exports.components = {
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-auto-collector-jsx": () => import("./../../../src/pages/auto-collector.jsx" /* webpackChunkName: "component---src-pages-auto-collector-jsx" */),
  "component---src-pages-certificates-jsx": () => import("./../../../src/pages/certificates.jsx" /* webpackChunkName: "component---src-pages-certificates-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-desilting-jsx": () => import("./../../../src/pages/desilting.jsx" /* webpackChunkName: "component---src-pages-desilting-jsx" */),
  "component---src-pages-download-jsx": () => import("./../../../src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-dumper-placer-jsx": () => import("./../../../src/pages/dumper-placer.jsx" /* webpackChunkName: "component---src-pages-dumper-placer-jsx" */),
  "component---src-pages-dustbins-jsx": () => import("./../../../src/pages/dustbins.jsx" /* webpackChunkName: "component---src-pages-dustbins-jsx" */),
  "component---src-pages-fogging-machine-jsx": () => import("./../../../src/pages/fogging-machine.jsx" /* webpackChunkName: "component---src-pages-fogging-machine-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-hook-loader-jsx": () => import("./../../../src/pages/hook-loader.jsx" /* webpackChunkName: "component---src-pages-hook-loader-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infrastructure-jsx": () => import("./../../../src/pages/infrastructure.jsx" /* webpackChunkName: "component---src-pages-infrastructure-jsx" */),
  "component---src-pages-mobile-toilet-bio-jsx": () => import("./../../../src/pages/mobile-toilet-bio.jsx" /* webpackChunkName: "component---src-pages-mobile-toilet-bio-jsx" */),
  "component---src-pages-multi-seater-toilet-jsx": () => import("./../../../src/pages/multi-seater-toilet.jsx" /* webpackChunkName: "component---src-pages-multi-seater-toilet-jsx" */),
  "component---src-pages-nala-cleaning-jsx": () => import("./../../../src/pages/nala-cleaning.jsx" /* webpackChunkName: "component---src-pages-nala-cleaning-jsx" */),
  "component---src-pages-portable-compactor-jsx": () => import("./../../../src/pages/portable-compactor.jsx" /* webpackChunkName: "component---src-pages-portable-compactor-jsx" */),
  "component---src-pages-portable-toilet-jsx": () => import("./../../../src/pages/portable-toilet.jsx" /* webpackChunkName: "component---src-pages-portable-toilet-jsx" */),
  "component---src-pages-refuse-compactor-jsx": () => import("./../../../src/pages/refuse-compactor.jsx" /* webpackChunkName: "component---src-pages-refuse-compactor-jsx" */),
  "component---src-pages-road-street-sweeper-jsx": () => import("./../../../src/pages/road-street-sweeper.jsx" /* webpackChunkName: "component---src-pages-road-street-sweeper-jsx" */),
  "component---src-pages-semi-automatic-jsx": () => import("./../../../src/pages/semi-automatic.jsx" /* webpackChunkName: "component---src-pages-semi-automatic-jsx" */),
  "component---src-pages-sewer-suction-jsx": () => import("./../../../src/pages/sewer-suction.jsx" /* webpackChunkName: "component---src-pages-sewer-suction-jsx" */),
  "component---src-pages-sky-lift-jsx": () => import("./../../../src/pages/sky-lift.jsx" /* webpackChunkName: "component---src-pages-sky-lift-jsx" */),
  "component---src-pages-suction-cum-jetting-jsx": () => import("./../../../src/pages/suction-cum-jetting.jsx" /* webpackChunkName: "component---src-pages-suction-cum-jetting-jsx" */),
  "component---src-pages-super-sucker-jsx": () => import("./../../../src/pages/super-sucker.jsx" /* webpackChunkName: "component---src-pages-super-sucker-jsx" */),
  "component---src-pages-truck-mounted-jsx": () => import("./../../../src/pages/truck-mounted.jsx" /* webpackChunkName: "component---src-pages-truck-mounted-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-water-tanker-jsx": () => import("./../../../src/pages/water-tanker.jsx" /* webpackChunkName: "component---src-pages-water-tanker-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

